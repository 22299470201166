import { useCallback, useEffect, useRef, useState } from "react";

import { Coordinate, NetherMap, Pathway, PortalLocation, PortalTags } from "../Types";
import { NetherPortal } from './NetherPortal';
import { MapGridlines } from './MapGridlines';
import { MapPathway } from './MapPathway';

type RailMapProps = Readonly<{
    map: NetherMap;
    highlightedPath?: Pathway;
    hiddenPaths: Pathway[];
    includedTags: PortalTags[];
    showPopover: (portalElement: HTMLElement, portal: PortalLocation) => void;
    hidePopover: () => void;
}>;

export const RailMap = ({ map, highlightedPath, hiddenPaths, includedTags, showPopover, hidePopover }: RailMapProps) => {
    const svgRef = useRef<SVGSVGElement | null>(null);
    const [svgSize, setSvgSize] = useState({ width: 0, height: 0 });
    const [isPointerPressed, setIsPointerPresed] = useState(false);

    const [lastPointerPosition, setLastPointerPosition] = useState({ x: 0, y: 0 });
    const [pan, setPan] = useState({ x: 0, y: 0 });
    const [zoomLevel, setZoomLevel] = useState(8);
    const [selectedPortalIndex, setSelectedPortalIndex] = useState(-1);

    const [mapSize, setMapSize] = useState({ width: 0, height: 0 });
    const [origin, setOrigin] = useState({ x: 0, y: 0 });

    const mouseWheelHandler = useCallback((e: WheelEvent) => {
        // TODO: For now dismiss the popup when panning/zooming since the popover doesn't
        // track correctly.
        hidePopover();
        setZoomLevel(e.deltaY > 0 ? zoomLevel - 1 : zoomLevel + 1);
        e.preventDefault();
    }, [zoomLevel]);

    // Compute the min/max coordiantes of the map and set the size and origin.
    useEffect(() => {
        let minX = 0;
        let minZ = 0;
        let maxX = 0;
        let maxZ = 0;
        for (let i = 0; i < map.pathways.length; i++) {
            for (let j = 0; j < map.pathways[i].path.length; j++) {
                for (let k = 0; k < map.pathways[i].path[j].length; k++) {
                    const pathNode = map.pathways[i].path[j][k];
                    if (pathNode.x < minX) {
                        minX = pathNode.x;
                    }
                    if (pathNode.x > maxX) {
                        maxX = pathNode.x;
                    }
                    if (pathNode.z < minZ) {
                        minZ = pathNode.z;
                    }
                    if (pathNode.z > maxZ) {
                        maxZ = pathNode.z;
                    }
                }
            }
        }

        for (let i = 0; i < map.portals.length; i++) {
            const currentPortal = map.portals[i];
            if (currentPortal.nether.x < minX) {
                minX = currentPortal.nether.x;
            }
            if (currentPortal.nether.x > maxX) {
                maxX = currentPortal.nether.x;
            }
            if (currentPortal.nether.z < minZ) {
                minZ = currentPortal.nether.z;
            }
            if (currentPortal.nether.z > maxZ) {
                maxZ = currentPortal.nether.z;
            }
        }

        setMapSize({ width: maxX - minX, height: maxZ - minZ });
        setOrigin({ x: minX, y: minZ });
    }, [map]);

    // Add the mousewheel handler to the SVG element.
    useEffect(() => {
        const svgElement = svgRef.current;
        const currentHandler = mouseWheelHandler;
        if (svgElement) {
            setSvgSize({ width: svgElement.clientWidth, height: svgElement.clientHeight });

            // By default React adds its wheel handler as passive: true, which prevents us
            // from stopping the event from propagating. We would like to capture it if the
            // cursor is within the SVG element so that we can prevent scrolling and use
            // the wheel to zoom.
            svgElement.addEventListener(
                "wheel",
                currentHandler,
                { passive: false });
        }

        return () => {
            svgElement?.removeEventListener("wheel", currentHandler);
        }
    }, [mouseWheelHandler]);

    return (
        <svg
            xmlns="<http://www.w3.org/2000/svg>"
            ref={svgRef}
            onPointerDownCapture={e => {
                setIsPointerPresed(true);
                setLastPointerPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
            }}
            onPointerUpCapture={e => setIsPointerPresed(false)}
            onPointerOutCapture={e => {
                const captureX = e.nativeEvent.offsetX;
                const captureY = e.nativeEvent.offsetY;

                // If we drag outside the svg, we'll treat it as a mouse-up event.
                if (isPointerPressed && (captureX < 0 || captureX > svgSize.width || captureY < 0 || captureY > svgSize.height)) {
                    setIsPointerPresed(false);
                }
            }}
            onPointerMoveCapture={e => {
                if (isPointerPressed) {
                    const deltaX = e.nativeEvent.offsetX - lastPointerPosition.x;
                    const deltaY = e.nativeEvent.offsetY - lastPointerPosition.y;
                    setLastPointerPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
                    setPan({ x: pan.x + deltaX, y: pan.y + deltaY })

                    // TODO: For now dismiss the popup when panning/zooming since the popover doesn't
                    // track correctly.
                    hidePopover();
                }
            }}
            style={{ flex: 1, background: "#631F1F" }}
            viewBox={`${origin.x} ${origin.y} ${mapSize.width} ${mapSize.height}`}
        >
            <g transform={`matrix(${1 + zoomLevel * 0.1} 0 0 ${1 + zoomLevel * 0.1} ${pan.x} ${pan.y})`}>
                <MapGridlines xOrigin={origin.x} yOrigin={origin.y} width={mapSize.width} height={mapSize.height} />
                {map.pathways.map((currentPathway: Pathway, index: number) => {
                    if (!hiddenPaths.includes(currentPathway)) {
                        return currentPathway.path.map((coordinates: Coordinate[], pathIndex: number) =>
                            <MapPathway
                                key={`${currentPathway.name}-${index}-${pathIndex}`}
                                name={currentPathway.name}
                                color={currentPathway.color}
                                isHighlighted={currentPathway === highlightedPath}
                                pathIndex={pathIndex}
                                pathway={coordinates} />);
                    } else {
                        return null;
                    }
                })}
                {map.portals.map((currentPortal: PortalLocation, index: number) => {
                    let includePortal = false;

                    for (let i = 0; i < includedTags.length; i++) {
                        if (currentPortal.tags?.includes(`${includedTags[i]}`)) {
                            includePortal = true;
                        }
                    }

                    if (includePortal || currentPortal.tags === undefined) {
                        return <NetherPortal
                            key={`${index}-${currentPortal.name}`}
                            portal={currentPortal}
                            isSelected={index === selectedPortalIndex}
                            currentZoom={1 + zoomLevel * 0.1}
                            onSelected={(portal: PortalLocation, e: HTMLElement) => {
                                setSelectedPortalIndex(index);
                                showPopover(e, portal);
                            }}
                        />;
                    }
                    else {
                        return null;
                    }
                })}
            </g>
        </svg>
    );
};