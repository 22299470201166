import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

import { PortalImage, PortalLocation } from "../Types";

type AlbumModalProps = Readonly<{
    showAlbum: boolean;
    hideAlbum: () => void;
    portal?: PortalLocation;
}>;

export const AlbumModal = ({ showAlbum, hideAlbum, portal }: AlbumModalProps) => {
    const onHide = () => hideAlbum();

    return (
        <Modal show={showAlbum} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{portal?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Carousel interval={null}>
                    {
                        portal?.images?.map((image: PortalImage, index: number) =>
                            <Carousel.Item key={`albumImage-${index}`}>
                                <img alt={`Portal: ${portal?.name} ${index} of ${portal?.images?.length}`} src={image.src} style={{ maxWidth: "100%" }} />
                                {!!image.comments && <Carousel.Caption>{image.comments}</Carousel.Caption>}
                            </Carousel.Item>
                        )
                    }
                </Carousel>
            </Modal.Body>
        </Modal>
    );
};