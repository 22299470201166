import { Coordinate } from "../Types";

type MapPathwayProps = Readonly<{
    name: string;
    color?: string;
    isHighlighted?: boolean;
    pathIndex: number;
    pathway: Coordinate[];
}>;

export const MapPathway = ({ name, color, isHighlighted, pathIndex, pathway }: MapPathwayProps) => {
    const elements: JSX.Element[] = [];

    for (let i = 0; i < pathway.length; i++) {
        const currentPoint = pathway[i];

        if (i + 1 < pathway.length) {
            const nextPoint = pathway[i + 1];
            let className = "path-line";

            if (isHighlighted) {
//                className += "-highlighted"
                elements.push(
                    <line
                        key={`path-${name}-${pathIndex}-${i}-highlight`}
                        style={{ strokeWidth: "0.22em", stroke: "yellow"}}
                        x1={currentPoint.x}
                        y1={currentPoint.z}
                        x2={nextPoint.x}
                        y2={nextPoint.z}
                    />
                );
            }
            elements.push(
                <line
                    className={className}
                    style={{ stroke: color ?? "red" }}
                    key={`path-${name}-${pathIndex}-${i}`}
                    x1={currentPoint.x}
                    y1={currentPoint.z}
                    x2={nextPoint.x}
                    y2={nextPoint.z}
                />
            );
        }
    }
    
    return <>{elements}</>;
};