import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PathSettingState {
    hiddenPathNames: string[]
};

const initialState: PathSettingState = {
    hiddenPathNames: []
};

export const pathSettingsSlice = createSlice({
    name: 'pathSettings',
    initialState,
    reducers: {
        hidePath: (state, action: PayloadAction<string>) => {
            if (state.hiddenPathNames.filter(x => x === action.payload) === undefined) {
                state.hiddenPathNames.push(action.payload);
            }
        },
        showPath: (state, action: PayloadAction<string>) => {
            const index = state.hiddenPathNames.indexOf(action.payload);

            if (index >= 0) {
                state.hiddenPathNames.splice(index, 1);
            }
        }
    }
});

export const { hidePath, showPath } = pathSettingsSlice.actions;

export default pathSettingsSlice.reducer;