import Badge from 'react-bootstrap/Badge';

import { PortalTags } from "../Types";

type Props = Readonly<{
    tag: PortalTags
    includedTags: PortalTags[]
    onClick: (tag: PortalTags) => void;
}>;

export const PortalTagBadge = ({ tag, includedTags, onClick }: Props) => {
    return (
        <Badge
            bg={includedTags.includes(tag) ? "success" : "secondary"}
            onClick={() => onClick(tag)}>
            { tag }
        </Badge>
    );
}