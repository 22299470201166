// The map gridlines are designed to align with level 4 map tiles in game. In Minecraft, a level 4
// map represents a 2048x2048 area, which when adjusted to Nether coordinates is 256 x 256. However,
// it centers 0,0 in the middle of a map square, meaning the "center" map tile goes from -128,128 
// in both X and Z coordinates. 
const MAP_SQUARE_SIZE = 256;
const MAP_LINE_OFFSET = 128;

type MapGridlinesProps = Readonly<{
    xOrigin: number;
    yOrigin: number;
    width: number;
    height: number;
}>;

export const MapGridlines = ({ xOrigin, yOrigin, width, height }: MapGridlinesProps) => {
    const minX = Math.floor(xOrigin / MAP_SQUARE_SIZE) * MAP_SQUARE_SIZE - MAP_LINE_OFFSET;
    const minY = Math.floor(yOrigin / MAP_SQUARE_SIZE) * MAP_SQUARE_SIZE - MAP_LINE_OFFSET;
    const maxX = Math.ceil((xOrigin + width) / MAP_SQUARE_SIZE) * MAP_SQUARE_SIZE + MAP_LINE_OFFSET;
    const maxY = Math.ceil((yOrigin + height) / MAP_SQUARE_SIZE) * MAP_SQUARE_SIZE + MAP_LINE_OFFSET;

    const numHorizontalLines = (maxY - minY) / MAP_SQUARE_SIZE;
    const numVerticalLines = (maxX - minX) / MAP_SQUARE_SIZE;

    const renderHorizontalLines = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i <= numHorizontalLines; i++) {
            const lineOffset = i * MAP_SQUARE_SIZE;

            elements.push(
                <line
                    className="map-grid-line"
                    key={`horizontal-grid-${i}`}
                    x1={minX}
                    y1={minY + lineOffset}
                    x2={maxX}
                    y2={minY + lineOffset}
                />
            );

        }

        return elements;
    }

    const renderVerticalLines = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i <= numVerticalLines; i++) {
            const lineOffset = i * MAP_SQUARE_SIZE;

            elements.push(
                <line
                    className="map-grid-line"
                    key={`vertical-grid-${i}`}
                    x1={minX + lineOffset}
                    y1={minY}
                    x2={minX + lineOffset}
                    y2={maxY}
                />
            );
        }

        return elements;
    }

    const renderMapLabels = () => {
        const elements: JSX.Element[] = [];

        for (let i = 0; i < numVerticalLines; i++) {
            for (let j = 0; j <= numHorizontalLines; j++) {
                const xOffset = i * MAP_SQUARE_SIZE;
                const yOffset = j * MAP_SQUARE_SIZE;

                elements.push(
                    <text
                        className="map-grid-text"
                        key={`map-label-${xOffset}-${yOffset}`}
                        x={minX + xOffset + 2}
                        y={minY + yOffset - 3}
                    >
                        ({minX + xOffset},{minY + yOffset})
                    </text>
                )
            }
        }

        return elements;
    }

    return (
        <g>
            {renderHorizontalLines()}
            {renderVerticalLines()}
            <g>
                {renderMapLabels()}
            </g>
        </g>
    );
};