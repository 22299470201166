import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';

import { Pathway, PortalLocation, PortalTags } from './Types';
import { AlbumModal } from './controls/AlbumModal';
import { PortalTagBadge } from './controls/PortalTagBadge';
import { RailMap } from './controls/RailMap';

import './App.css';

import NetherMap from "./data/argyre.nether.json";

// TODOs
// chunk types?
// Create "type" on portal, allow filters - make these tags so multiple ones can be on there
// Add filters to Offcanvas
// Allow highlighting a path on Offcanvas
// Create schema file
// Points of interest pop-over needs to move after a zoom/pan

/*
 * TODO: Private mineshafts, these should be kept out of any version made public
 */
const netheriteMineshafts: Pathway = {
  type: "mineshaft",
  name: "Mykroft's Netherite Mineshafts",
  color: "darkgray",
  path: [
    [
      { x: 960, y: 14, z: 611, comments: "Start of Crimson Forest Strip Mine" },
      { x: 960, y: 14, z: 128 },
      { x: 960, y: 14, z: 208 },
      { x: 855, y: 12, z: 208 },
      { x: 855, y: 12, z: 234 },
      { x: 853, y: 13, z: 213 },
    ],
    [
      { x: 1382, y: 14, z: -193, comments: "Start of Strip Mine near Geode Main Path" },
      { x: 1548, y: 14, z: -193 }
    ],
    [
      { x: 1440, y: 14, z: -193 },
      { x: 1440, y: 14, z: -341 }
    ],
    [
      { x: 1440, y: 14, z: -193 },
      { x: 1440, y: 14, z: -146 }
    ],
    [
      { x: 1893, y: 14, z: 49 },
      { x: 1936, y: 14, z: 49 },
      { x: 1936, y: 14, z: -49 },
      { x: 2033, y: 14, z: -49 },
      { x: 2033, y: 14, z: -304, comments: "I was finding blackstone and hit a few ancient debri here" },
      { x: 1759, y: 14, z: -304 },
      { x: 1759, y: 14, z: -193 },
      { x: 1441, y: 14, z: -193 },
      { x: 1441, y: 14, z: -341 },

    ]
  ]
};

// basalt chunk 1759, 14, -203

const trailToCrimsonForest: Pathway = {
  type: "foottrail",
  name: "Path to Crimson Forest",
  color: "lightgray",
  path: [[
    { x: 623, y: 69, z: 126 },
    { x: 623, y: 51, z: 34 },
    { x: 441, y: 50, z: 34 },
  ]]
};

function App() {
  const divRef = useRef(null);

  // TODO: Move these to redux
  const [selectedPortal, setSelectedPortal] = useState<PortalLocation | undefined>(undefined);
  const [highlightedPath, setHighlightedPath] = useState<Pathway | undefined>(undefined);
  const [hiddenPaths, setHiddenPaths] = useState<Pathway[]>([]);

  const [includedTags, setIncludedTags] = useState([
    PortalTags.Base,
    PortalTags.Farm,
    PortalTags.Villagers,
    PortalTags.Builds,
    PortalTags.Exploration
  ]);

  const [showAlbum, setShowAlbum] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [popoverTarget, setPopoverTarget] = useState<HTMLElement | null>(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const keyHandler = useCallback((e: KeyboardEvent): any => {
    if (e.key === "Escape") {
      setSelectedPortal(undefined);
      setShowPopover(false);
    }

    return;
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", keyHandler);
    return () => document.removeEventListener("keyup", keyHandler, false)
  }, [keyHandler]);

  const OnTagBadgeClicked = (tag: PortalTags) => {
    if (includedTags.includes(tag)) {
      setIncludedTags(includedTags.filter(p => p !== tag))
    } else {
      setIncludedTags([
        ...includedTags,
        tag
      ]);
    }
  }

  return (
    <div className="App">
      {searchParams.get("realm") === "argyre" ?
        <>
          <div ref={divRef} style={{ color: "white", fontSize: 24 }}>
            {NetherMap.name}
            <Button variant="danger" style={{ position: "absolute", top: 0, right: 0 }} onClick={() => setShowOffcanvas(true)}>Menu</Button>
          </div>
          <RailMap
            map={NetherMap}
            includedTags={includedTags}
            hiddenPaths={hiddenPaths}
            highlightedPath={highlightedPath}
            showPopover={(e: HTMLElement, portal: PortalLocation) => {
              setPopoverTarget(e);
              setSelectedPortal(portal);
              setShowPopover(true);
            }}
            hidePopover={() => setShowPopover(false)}
          />
          <div>
            <Overlay
              show={showPopover && !showAlbum}
              target={popoverTarget}
              placement="bottom"
              container={divRef}
            >
              <Popover id="portal-popover">
                <Popover.Header>
                  {selectedPortal?.name}
                </Popover.Header>
                <Popover.Body>
                  <div>Nether X: {selectedPortal?.nether.x} Y: {selectedPortal?.nether.y ?? "UNK"} Z: {selectedPortal?.nether.z}</div>
                  {selectedPortal?.overworld && <div>Overworld X: {selectedPortal?.overworld!.x} Y: {selectedPortal?.overworld!.y ?? "UNK"} Z: {selectedPortal.overworld!.z}</div>}
                  {
                    (selectedPortal?.tags?.length ?? 0) > 0 &&
                    selectedPortal?.tags?.map(tag => <Badge bg="success">{tag}</Badge>)
                  }
                  {
                    (selectedPortal?.images?.length ?? 0) > 0 &&
                    <div>
                      <div>
                        <img style={{ maxWidth: "100%" }} onClick={e => setShowAlbum(true)} src={selectedPortal?.images![0].src} />
                      </div>
                      <div onClick={e => setShowAlbum(true)}>
                        <small>Click image to see more</small>
                      </div>
                    </div>
                  }
                </Popover.Body>
              </Popover>
            </Overlay>
            <AlbumModal portal={selectedPortal} showAlbum={showAlbum} hideAlbum={() => setShowAlbum(false)} />
            <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Accordion>
                  <Accordion.Item eventKey={"pathways"}>
                    <Accordion.Header>Railways and Pathways</Accordion.Header>
                    <Accordion.Body>
                      <ListGroup>
                        {
                          NetherMap.pathways.map((pathway, index) =>
                            <ListGroup.Item key={`flyout-${pathway}-${index}`} active={highlightedPath === pathway}>
                              <div style={{ fontWeight: "bold" }}>{pathway.name}</div>
                              <div style={{ fontSize: "12px" }}>{pathway.description}</div>
                              <div>
                                <ButtonGroup>
                                  {
                                    highlightedPath === pathway ?
                                      <Button variant="success" onClick={() => setHighlightedPath(undefined)}>Unselect</Button> :
                                      <Button variant="secondary" onClick={() => setHighlightedPath(pathway)}>Select</Button>
                                  }
                                  {hiddenPaths.includes(pathway) ?
                                    <Button variant="success" onClick={() => {
                                      setHiddenPaths(hiddenPaths.filter(p => p !== pathway));
                                    }}>
                                      Unhide
                                    </Button>
                                    :
                                    <Button variant="secondary" onClick={() => {
                                      if (highlightedPath === pathway) {
                                        setHighlightedPath(undefined);
                                      }

                                      setHiddenPaths([
                                        ...hiddenPaths,
                                        pathway
                                      ]);
                                    }}>Hide</Button>
                                  }
                                </ButtonGroup>
                              </div>
                            </ListGroup.Item>
                          )
                        }
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey={"portalTools"}>
                    <Accordion.Header>Portal Filters</Accordion.Header>
                    <Accordion.Body>
                      <div>Show Portals with any of the following tags:</div>
                      <div>
                        <PortalTagBadge
                          tag={PortalTags.Base}
                          includedTags={includedTags}
                          onClick={OnTagBadgeClicked}
                        />
                        <PortalTagBadge
                          tag={PortalTags.Farm}
                          includedTags={includedTags}
                          onClick={OnTagBadgeClicked}
                        />
                        <PortalTagBadge
                          tag={PortalTags.Villagers}
                          includedTags={includedTags}
                          onClick={OnTagBadgeClicked}
                        />
                        <PortalTagBadge
                          tag={PortalTags.Builds}
                          includedTags={includedTags}
                          onClick={OnTagBadgeClicked}
                        />
                        <PortalTagBadge
                          tag={PortalTags.Exploration}
                          includedTags={includedTags}
                          onClick={OnTagBadgeClicked}
                        />

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </>
        :
        <div style={{ display: "flex", height: "100vh" }}>
          <img style={{ width: "100%", alignSelf: "center", verticalAlign: "center", marginRight: 150, marginLeft: 150 }} alt="It Is A Mystery" src="iiam.svg" />
        </div>
      }
    </div>
  );
}

export default App;
