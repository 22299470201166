export enum PortalTags {
    Base = "Base",
    Farm = "Farm",
    Villagers = "Villagers",
    Builds = "Builds",
    Exploration = "Exploration"
};

export type Coordinate = Readonly<{
    x: number,
    y?: number,
    z: number,
    comments?: string 
  }>;
  
export type Pathway = Readonly<{
    type: string,
    name: string,
    color?: string;
    path: Coordinate[][]
}>;

export type PortalImage = Readonly<{
    src: string;
    comments?: string;
}>;

/*
 * Represents the location of a portal in the Nether. At a minimum
 * it should have a name and x,z coordinate. However, coordinates for
 * the overworld can also be added, as well as adjustments to where the
 * text is rendered relative to the portal icon for denser maps.
 */
export type PortalLocation = Readonly<{
    name: string;
    nether: Coordinate;
    overworld?: Coordinate;
    comments?: string;
    adjustX?: number;
    adjustY?: number;
    images?: PortalImage[];
    tags?: string[];
}>;

export type NetherMap = Readonly<{
    portals: PortalLocation[];
    pathways: Pathway[];
}>;