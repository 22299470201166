import { useState } from "react";
import { PortalLocation } from "../Types";

type NetherPortalProps = Readonly<{
    portal: PortalLocation,
    isSelected?: boolean;
    onSelected?: (portal: PortalLocation, target: any) => void;
    currentZoom: number;
}>;

export const NetherPortal = ({ portal, isSelected, onSelected, currentZoom }: NetherPortalProps) => {
    const portalWidth = 30;
    const portalHeight = 50;
    const portalTranslateX = portalWidth + (portal.adjustX ?? 10);
    const portalTranslateY = portalHeight - (portal.adjustY ?? 10);
    const [isHovered, setIsHovered] = useState(false);

    const portalPosition = { x: portal.nether.x * currentZoom, y: portal.nether.z * currentZoom };

    return (
        <g transform={`matrix(${1 / currentZoom} 0 0 ${1 / currentZoom} 0 0)`}>
            <rect
                className="portal-icon"
                width={portalWidth}
                height={portalHeight}
                x={portalPosition.x - (portalWidth / 2)}
                y={portalPosition.y - (portalHeight / 2)}
                onMouseEnter={e => setIsHovered(true)}
                onMouseLeave={e => setIsHovered(false)}
                onClick={e => onSelected?.(portal, e.target)}
            />
            <text
                className={isSelected || isHovered ? "portal-text selected" : "portal-text"}
                x={portalPosition.x - (portalWidth / 2)}
                y={portalPosition.y - (portalHeight / 2)}
                transform={`translate(${portalTranslateX} ${portalTranslateY})`}>
                {portal.name}
            </text>
        </g>
    );
};